<template>
  <div class="footer-class">
    <router-link
      class="na-class"
      v-for="(item, index) in route"
      :key="'na' + index"
      :to="item.route"
      ><el-image :src="url" fit="cover" style="width: 30px"></el-image
      >{{ item.title }}</router-link
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: require("../assets/na.png"),
      route: [
        { title: "意见汇总", route: "/" },
        { title: "反馈意见", route: "/submit" },
        { title: "订阅通知", route: "/own" },
        { title: "个人主页", route: "/personal" },
      ],
    };
  },
  mounted() {
    if (sessionStorage.getItem("openid")) {
      this.$axios
        .get("/appealapi/getu.php?o=" + sessionStorage.getItem("openid"))
        .then((res) => {
          if (res.data.identity == 0 || res.data.identity == 1) {
            this.route.push({ title: "后台", route: "/backstage" });
          }
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.footer-class {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  bottom: 0px;
  background: #f7f7fa;
  left: 0px;
  .na-class {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
    color: #bbbbbb;
    width: 20%;
  }
}
</style>