import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/style/public.scss'

// axios配置
import axios from 'axios'
Vue.prototype.$axios = axios

// element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// Echarts
import 'echarts'
import ECharts from 'vue-echarts'
Vue.component('v-chart', ECharts)


// 脚部导航
import Footer from "@/components/Footer";
Vue.component("Footer", Footer)

// import VWechatAuth from 'v-wechat-auth'
// Vue.use(VWechatAuth)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  // wechatAuth: new VWechatAuth({
  //   appId: "wx0b22e4f50df6f226",
  //   scope: "snsapi_base",
  //   authorize() {
  //     return axios.get('http://www.lingmoushijie.com', { params: { code: code } })
  //       .then(function (res) {
  //         var data = (res && res.data) || {} // response data should at least contain openid
  //         return data
  //       })
  //   }
  // })
}).$mount('#app')
