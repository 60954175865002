<template>
  <div id="app">
    <router-view class="router-view-class" />
    <Footer v-if="$route.path != '/login'" />
  </div>
</template>

<script>
export default {
  mounted() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
  },
  methods: {
    beforeunloadHandler(e) {
      sessionStorage.clear();
    },
  },
  destroyed() {
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e)
    );
  },
};
</script>
<style lang="scss">
#app {
  width: 100vw;
  height: 100vh;
  .router-view-class {
    width: 100%;
    height: calc(100% - 50px);
    overflow-x: hidden;
    overflow-y: auto;
  }
}
</style>

