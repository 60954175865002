import axios from 'axios'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: { title: '意见汇总' }
  },
  {
    path: '/details',
    name: 'Details',
    component: () => import('../views/Details.vue'),
    meta: { title: '详情' }
  },
  {
    path: '/submit',
    name: 'Submit',
    component: () => import('../views/Submit.vue'),
    meta: { title: '反馈建议' }
  },
  {
    path: '/own',
    name: 'Own',
    component: () => import('../views/Own.vue'),
    meta: { title: '订阅通知' }
  },
  {
    path: '/backstage',
    name: 'Backstage',
    component: () => import('../views/Backstage.vue'),
    meta: { title: '后台' }
  },
  {
    path: '/reply',
    name: 'Reply',
    component: () => import('../views/Reply.vue'),
    meta: { title: '回复' }
  },
  {
    path: '/personal',
    name: 'Personal',
    component: () => import('../views/Personal.vue'),
    meta: { title: '个人页面' }
  },
  {
    path: '/edition',
    name: 'Edition',
    component: () => import('../views/Edition.vue'),
    meta: { title: '关于我们' }
  },
  {
    path: '/types',
    name: 'Types',
    component: () => import('../views/Types.vue'),
    meta: { title: '类别管理' }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: { title: '登录' }
  }
]


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(function (to, from, next) {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  // 写入微信登陆功能，并执行路由守卫
  // if (from.query.code) {
  //   to.query['code'] = from.query.code
  // }
  // if (to.query.code) {
  //   axios.get('/appealapi/getopenid.php?code=' + to.query.code).then((res) => {
  //     console.log(res.data)
  //   })
  // }
  // sessionStorage.setItem('username', 'admin')
  // sessionStorage.setItem('openid', 'u')
  if(to.path!='/login'){
    if(sessionStorage.getItem('username')){
      next()
    }else{
      next('/login')
    }
  }else{
    next()
  }
  // next('/login')
  // next()
})

export default router
